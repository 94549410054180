<template>
  <div id="clothing" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <div class="columns">
          <div class="column is-6 is-offset-3">
            <form @submit.prevent="passes(submit)">
              <SdInput
                type="text"
                rules="required|min:2|max:30"
                label="Name"
                v-model="clothing.name"
              />

              <SdInput
                type="text"
                rules="required|min:2|max:30"
                label="Amount"
                v-model="clothing.amount"
              />

              <button
                type="submit"
                class="button btn-120 is-primary is-capitalized is-pulled-right"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SdInput from '../../components/SdInput'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '../../assets/js/notification'

export default {
  data() {
    return {
      schoolId: null,
      pageTitle: 'Clothing',
      skipQuery: true,
      clothing: {
        id: null,
        name: '',
        amount: '',
      },
    }
  },
  apollo: {
    clothing: {
      query: gql`
        query ClothingQuery($id: ID!) {
          clothing(id: $id) {
            id
            name
            amount
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
      skip() {
        return this.skipQuery
      },
    },
  },
  methods: {
    submit() {
      if (this.$route.params.id === 'new') {
        // Add
        this.$apollo
          .mutate({
            mutation: gql`
              mutation CreateClothingQuery(
                $schoolId: Int!
                $name: String!
                $amount: Float!
              ) {
                createClothing(
                  input: { schoolId: $schoolId, name: $name, amount: $amount }
                ) {
                  clothing {
                    id
                  }
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              schoolId: this.schoolId,
              name: this.clothing.name,
              amount: parseFloat(this.clothing.amount),
            },
          })
          .then((response) => {
            crudNotification(
              response.data.createClothing.errors,
              'Successfully created.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/clothings`
              )
            })
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation UpdateClothingQuery(
                $id: Int!
                $name: String!
                $amount: Float!
              ) {
                updateClothing(
                  input: { id: $id, name: $name, amount: $amount }
                ) {
                  clothing {
                    id
                  }
                  errors
                }
              }
            `,
            variables: {
              id: parseInt(this.clothing.id),
              name: this.clothing.name,
              amount: parseFloat(this.clothing.amount),
            },
          })
          .then((response) => {
            crudNotification(
              response.data.updateClothing.errors,
              'Successfully updated.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/clothings`
              )
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
  },
  components: {
    SdInput,
    ValidationObserver,
  },
  mounted() {
    if (this.$route.params.id !== 'new') this.skipQuery = false
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)

    this.$store.commit('setSubMenus', [
      {
        name: 'Clothings',
        route: `/school/${this.schoolId}/clothings`,
      },
    ])
  },
}
</script>
